<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="shortener">Short Url List</h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'shortener.new'}">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="shorts.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :loading="loading"
                @update:column-filter-value="updateFilters"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
               <template #id="{item}">
                <td>
                  <a :href="generateUrl(item.id)" target="_blank">{{ item.id }}</a>
                </td>
              </template>
              <template #long_url="{item}">
                <td>
                  <a :href="item.long_url" target="_blank" v-if="item.long_url">{{ item.long_url }}</a>
                  <span v-else>N/A</span>
                </td>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="primary" class="mr-2"
                           @click="$router.push({name: 'shortener.edit', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CButton v-if="hasMoreShorts" @click="fetchMore" v-translate translate-context="list.actions">Load more</CButton>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListShortUrl',

  data() {
    return {
      loading: true,
      fields: [
        {key: 'id', label: this.$gettextInterpolate('Short Url','shortener.list.tbl'), _style: 'width:100px;'},
        {key: 'long_url', label: this.$gettextInterpolate('Long Url','shortener.list.tbl')},
        {key: 'actions', label: this.$gettextInterpolate('Actions','shortener.list.tbl'), sorter: false, filter: false}
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      filters: {},
    }
  },
  async mounted() {
    await this.fetchList()
  },
  computed: {
    ...mapGetters('shortener', [
      'shorts',
      'hasMoreShorts',
    ]),
  },
  methods: {
    ...mapActions('shortener', [
      'fetchShortList',
      'fetchMoreShorts',
      'deleteShort'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchShortList({filters: this.filters})
      this.loading = false
    },
    async fetchMore() {
      this.loading = true
      await this.fetchMoreShorts({filters: this.filters})
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    async onDelete(id) {
      if (!confirm('Are you sure?')) {
        return
      }
      await this.deleteShort(id)
      await this.fetchList()
    },
    generateUrl(short) {
      return `${process.env.VUE_APP_PICA_SHORTENER_BACKEND}/${short}`
    }
  }
}
</script>

<style scoped>

</style>